.customer-edit-component .MuiGrid-root .MuiPaper-root {
    border-radius: 0px 0px 4px 4px;
}

.discount-box.active {
    background-color: #ecf2fb;
}

.discount-box.inactive {
    background-color: #f0f3f5;
}

.summary-header {
    overflow-x: hidden;
    font-size: 12px;
    margin-bottom: 10px;
}

.summary-header:before {
    float: left;
    width: 0;
    white-space: nowrap;
    content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
}

.summary-header span {
    float: right;
    background-color: white;
    padding-left: 5px;
}

.summary-header p {
    background-color: white;
    margin: 0px;
    display: inline-block;
}

.list-bottom {
    bottom: 0;
    position: absolute !important;
    width: 100%;
}

.MuiPaper-root.menu-theme {
    background-color: #3a444d;
    overflow: hidden;
}

.MuiPaper-root.menu-theme.sub-menu,
.MuiPaper-root.menu-theme.sub-menu-sm {
    background-color: #2f363e;
    overflow: hidden;
}

.MuiCollapse-container.nested-menu {
    background-color: #2f363e;
}

.MuiDrawer-paperAnchorLeft.sub-menu-sm {
    left: 50px;
}

.MuiDrawer-paperAnchorLeft.sub-menu {
    left: 240px;
}

.MuiPaper-root.menu-theme .MuiListItemIcon-root,
.MuiPaper-root.menu-theme .MuiListItemText-root,
.MuiPaper-root.menu-theme .MuiSvgIcon-root {
    color: #a4a8ab;
}

.MuiPaper-root.menu-theme .MuiListItem-root:hover .MuiSvgIcon-root,
.MuiPaper-root.menu-theme .MuiListItem-root:hover .MuiListItemIcon-root,
.MuiPaper-root.menu-theme .MuiListItem-root:hover .MuiListItemText-root {
    color: #ffffff;
}

.current-section {
    background-color: #47535d !important;
}

.current-submenu {
    background-color: #2f363e !important;
}

.current-section::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 3px;
    background-color: #5e91eb;
}

.sub-menu-backdrop {
    left: 240px !important;
}

.sub-menu-backdrop-sm {
    left: 50px !important;
}

.sub-menu-item > span {
    font-size: 12px;
}

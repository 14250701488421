.inline-alert {
    padding: 10px;
    margin: 10px 0;
}
.inline-alert.inline-alert-warning {
    background-color: var(--soft-tangerine);
    border-left: 5px solid var(--tangerine);
}
.inline-alert.inline-alert-error {
    background-color: var(--very-light-pink);
    border-left: 5px solid var(--red-orange);
}

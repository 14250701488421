.alert {
    border: 0;
    border-radius: 0;
    font-size: 14px;
    padding: 10px;
    color: var(--gunmetal);
    margin-top: 20px;
}

.icon-container {
    display: inline-block;
    vertical-align: middle;
}

.alert svg {
    margin-right: 10px;
}

.alert.alert-info {
    background-color: var(--duck-egg-blue);
    border-left: 5px solid var(--lavender-two);
}

.alert.alert-info svg {
    color: var(--soft-blue);
    width: 22px;
    height: 22px;
}

.alert.alert-warning {
    background-color: var(--soft-tangerine);
    border-left: 5px solid var(--tangerine);
}

.alert.alert-warning svg {
    color: var(--tangerine);
    width: 25px;
    height: 25px;
}

.alert.alert-error {
    background-color: var(--very-light-pink);
    border-left: 5px solid var(--red-orange);
}

.alert.alert-error svg {
    color: var(--red-orange);
    width: 22px;
    height: 22px;
}

td.emitted,
label.emitted {
    color: green;
}

td.payment-pending,
label.payment-pending {
    color: brown;
}

td.draft,
label.draft {
    color: gray;
}

td.rectified,
label.rectified {
    color: orange;
}

td.paid {
    color: green;
}

td.failed,
label.failed {
    color: red;
}

td.pending {
    color: orange;
}

th.actions {
    text-align: right;
}

tr.deleted {
    background-color: #e8e8e8;
}
tr.deleted td,
tr.deleted td label,
tr.deleted td div {
    color: #a9a9a9 !important;
}
